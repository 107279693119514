<template>
  <div>
    <div class="toppicd">
      <img src="../../../Newimg/tougao.jpg" alt="" />
    </div>
    <div class="liuchengtu" id="process">
      <div class="w">
        <div class="enter">
          <mytitle>智能投稿流程</mytitle>

          <ul class="enter_cont">
            <li v-for="(item, index) in procedure" :key="index">
              <div class="img">
                <img :src="item.src" alt="" />
                <span class="spans">
                  {{ item.text }}
                </span>
              </div>
              <div class="arrow">
                <img src="../../assets/image/enter/arrow2.png" alt="" />
              </div>
            </li>
          </ul>
          <div class="application" @click="skip">立即投稿</div>
        </div>
      </div>
    </div>
    <div class="bg" id="youshi">
      <div class="w">
        <div class="advantage">
          <mytitle>五大功能</mytitle>
          <ul>
            <li v-for="(item, index) in advantage" :key="index">
              <div class="aimg">
                <img :src="item.src" alt="" />
              </div>
              <div class="text">
                <span>{{ item.title }}</span>
                <p>{{ item.cont }}</p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="liuchengtu" id="guarantee">
      <div class="w">
        <div class="enter" style="padding-bottom: 0">
          <mytitle>六大保障</mytitle>
          <div class="ptyscontent">
            <ul>
              <li v-for="(item, index) in advantagess" :key="index">
                <div>
                  <img :src="item.src" alt="" />
                </div>
                <div class="item-content">
                  <b>{{ item.title }}</b>
                  <p class="conent">{{ item.cont }}</p>
                </div>
              </li>
            </ul>
            <div class="baozhang">
              <img src="../../assets/image/baozhang.png" alt="" />
            </div>
            <ul>
              <li v-for="(item, index) in advantages" :key="index">
                <div>
                  <img :src="item.src" alt="" />
                </div>
                <div class="item-content">
                  <b>{{ item.title }}</b>
                  <p class="conent">{{ item.cont }}</p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="bg2">
      <div class="w">
        <div class="enter">
          <mytitle>智能编辑</mytitle>
          <div class="ptyscontents">
            <ul>
              <li v-for="(item, index) in imgsList" :key="index">
                <div>
                  <img :src="item.src" alt="" />
                </div>
                <div>
                  <p class="conent">{{ item.cont }}</p>
                  <el-button type="primary" class="btns" @click="skip"
                    >立即投稿</el-button
                  >
                </div>
              </li>
            </ul>
            <div class="baozhang2">
              <img src="../../assets/image/baozhang2.png" alt="" />
            </div>
            <ul>
              <li v-for="(item, index) in imgsLists" :key="index">
                <div>
                  <img :src="item.src" alt="" />
                </div>
                <div>
                  <p class="conent">{{ item.cont }}</p>
                  <el-button type="primary" class="btns" @click="skip"
                    >立即投稿</el-button
                  >
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="bg2" id="questions">
      <div class="w">
        <div class="enter">
          <mytitle>常见问题</mytitle>
          <div class="questions-box">
            <div
              class="questions-item"
              v-for="(item, index) in problemList"
              :key="index"
            >
              <div class="questions">
                <span class="questionsactive">Q</span>
                {{ item.problem }}
              </div>
              <div class="answer">
                <span class="questionsactive">A</span>
                <div style="flex: 1" v-html="item.answer"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Topic from "@/components/Topics.vue";
import mytitle from "@/components/title.vue";
import { problemByType } from "../../API/homepage/problem.js";
export default {
  components: {
    Topic,
    mytitle,
  },
  data() {
    return {
      pageNum: 1,
      limit: 10,
      dialogVisible: false,
      //入驻流程数据
      procedure: [
        {
          src: require("../../assets/image/nejknj07.png"),
          text: "上传稿件",
        },
        {
          src: require("../../assets/image/nejknj08.png"),
          text: "平台审核",
        },
        {
          src: require("../../assets/image/nejknj0.png"),
          text: "作者付费",
        },
        {
          src: require("../../assets/image/nejknj09.png"),
          text: "期刊复审",
        },
        {
          src: require("../../assets/image/nejknj06.png"),
          text: "刊发录用",
        },
        {
          src: require("../../assets/image/nejknj11.png"),
          text: "出刊邮寄",
        },
      ],
      //平台优势数据
      advantage: [
        {
          src: require("../../assets/image/banner01.png"),
          title: "智能识别",
          cont: "上传您的文件，平台自动识别文档格式",
        },
        {
          src: require("../../assets/image/banner02.png"),
          title: "在线指导",
          cont: "知名高校、权威机构专家、教授在线指导",
        },
        {
          src: require("../../assets/image/banner03.png"),
          title: "智能推荐",
          cont: "根据您的论文信息智能推荐符合您要求的期刊",
        },
        {
          src: require("../../assets/image/banner04.png"),
          title: "辅助纠错",
          cont: "系统自动检测敏感词汇，帮您避免烦恼",
        },
        {
          src: require("../../assets/image/banner05.png"),
          title: "参考文献",
          cont: "海量论文免费下载，为您省去下载成本",
        },
      ],
      advantagess: [
        {
          src: require("../../assets/image/ptbz1.png"),
          title: "资质保障",
          cont: "经工商、工信、公安、网监等国家管局批复许可，正刊保障",
        },
        {
          src: require("../../assets/image/ptbz2.png"),
          title: "保密协议",
          cont:
            "根据您的论平台数据库多点分布式安全布防，全程保护作者隐私文信息智能推荐符合您要求的期刊",
        },

        {
          src: require("../../assets/image/ptbz3.png"),
          title: "退款无忧",
          cont:
            "海量论文免费如无录用，实时无条件退款，原支付渠道返还，实时到账下载，为您省去下载成本",
        },
      ],
      imgsList: [
        {
          src: require("../../assets/image/nejknj15.png"),
          cont:
            "根据写作方向，推荐最新的写作思路，给出多个选题方案，不在为写什么发愁！选题查重，尽量避免相同题目出现，力求选题科学、新颖。",
        },

        {
          src: require("../../assets/image/nejknj14.png"),
          cont:
            "全网智能搜索文献、高质量文章随意阅读、下载、参考，“参考文献”类别齐全，帮助写作太简单了",
        },
      ],
      imgsLists: [
        {
          src: require("../../assets/image/nejknj17.png"),
          cont:
            "智能规范文章格式，题目、摘要、关键词、正文、参考文献，写作如同填空错别字、敏感词智能过滤，真方便！",
        },

        {
          src: require("../../assets/image/nejknj16.png"),
          cont:
            "平台提供全网最全的多网联合检测查重，海量文库对比，精准捕捉，检测过程原文受加密保护，永不外泄！",
        },
      ],
      advantages: [
        {
          src: require("../../assets/image/ptbz4.png"),
          title: "帮助选题 推荐前沿",
          cont: "AI智能的分析+区块链加密保护技术，成果不用担心被盗用",
        },

        {
          src: require("../../assets/image/ptbz5.png"),
          title: "规范格式 填空式写作",
          cont: "无中间商环节，稿件直达杂志上入驻平台，审核流程公开公正",
        },

        {
          src: require("../../assets/image/ptbz6.png"),
          title: "参考资料 全网海量资料",
          cont: "早八晚八保姆式服务，随时解决用户疑问，让您没有后顾之忧",
        },
      ],
      problemList: [],
      //合作伙伴
      coopration: [
        {
          src: require("../../assets/image/enter/h1.png"),
        },
        {
          src: require("../../assets/image/enter/h2.png"),
        },
        {
          src: require("../../assets/image/enter/h3.png"),
        },
        {
          src: require("../../assets/image/enter/h4.png"),
        },
        {
          src: require("../../assets/image/enter/h5.png"),
        },
        {
          src: require("../../assets/image/enter/h6.png"),
        },
        {
          src: require("../../assets/image/enter/h7.png"),
        },
        {
          src: require("../../assets/image/enter/h8.png"),
        },
        {
          src: require("../../assets/image/enter/h9.png"),
        },
        {
          src: require("../../assets/image/enter/h0.png"),
        },
      ],
    };
  },
  created() {
    if (this.$route.query.id) {
      this.now = this.$route.query.id;
      setTimeout(() => {
        var element = document.getElementById(this.$route.query.id);
        console.log(element, this.$route.query.id);
        if (element) {
          element.scrollIntoView();
        }
      }, 200);
    }
    this.getQuestion();
  },
  watch: {
    $route(to, from) {
      console.log("监听到路由变动了", to.path);
      if (this.$route.query.id) {
        this.now = this.$route.query.id;
        setTimeout(() => {
          var element = document.getElementById(this.$route.query.id);
          console.log(element, this.$route.query.id);
          if (element) {
            element.scrollIntoView();
          }
        }, 200);
      }
    },
  },
  mounted() {},
  methods: {
    async getQuestion() {
      // 1.智能投稿常见问题2.顾问服务常见问题3.平台入驻常见问题4.关于我们常见问题
      problemByType(this.pageNum, this.limit, 1).then((res) => {
        this.problemList = res.data.data;
      });
    },
    skip() {
      if (localStorage.getItem("code")) {
        if (localStorage.getItem("code") === "0") {
          this.$router.push("/Pending/contribute");
        } else {
          this.$alert("请注册成作者在进行投稿！", "", {
            confirmButtonText: "确定",
            callback: (action) => {
              // this.$router.push("/login");
            },
          });
        }
      } else {
        this.$confirm("您还不是作者,请注册成作者在进行投稿?", "提示", {
          cancelButtonText: "取消",
          confirmButtonText: "确定",
          type: "warning",
        })
          .then(() => {
            this.$router.push("/login");
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消",
            });
          });
      }
    },
  },
};
</script>

<style>
.questions-box {
  width: 100%;
  background: #f7f7f7;
  padding: 0 26px;
  padding-bottom: 80px;
  margin-top: 54px;
}
.questions-box .questions-item {
  height: 180px;
  box-sizing: border-box;
  padding-top: 50px;
  border-bottom: 1px solid #e5e5e5;
}
.questions-box .questions-item .questionsactive {
  width: 20px;
  height: 20px;
  color: #fff;
  background: #1082ff;
  display: inline-flex;
  font-size: 12px;
  align-items: center;
  margin-right: 16px;
  justify-content: center;
}
.questions {
  display: flex;
  align-items: center;
  font-size: 18px;
  color: #1082ff;
}
.answer {
  display: flex;
  margin-top: 26px;
  font-size: 14px;
  line-height: 20px;
  color: #606060;
}
.enter {
  padding: 10px 0 26px 0;
}

.liuchengtu {
  width: 100%;
  height: auto;

  background-color: #ffffff;
}

.enter_cont {
  display: flex;
  justify-content: space-between;
}

.enter_cont li {
  display: flex;
}

.enter_cont li .arrow img {
  margin-left: 24px;
  margin-top: 10px;
}

.img {
  color: #333;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.img img {
  width: 80px;
  height: 80px;
  margin-bottom: 8px;
  display: block;
}

.spans {
  width: 100px;
  text-align: center;
}

.enter_cont li:last-child .arrow {
  display: none;
}

.application {
  cursor: pointer;
  margin: 0 auto;
  margin-top: 30px;
  width: 180px;
  height: 48px;
  background: #ff1918;
  border-radius: 4px;
  color: #fff;
  text-align: center;
  line-height: 48px;
}

.bg {
  background-color: #f7f7f7;
  padding: 20px;
  padding-bottom: 40px;
}
.bg2 {
  padding: 20px;
  padding-bottom: 40px;
  background: #fff;
  border-top: #f7f7f7 solid 90px;
}
.advantage ul {
  display: flex;
  justify-content: space-between;
  text-align: center;
}

.advantage ul li {
  background-color: #fff;
  margin: 0 10px;
  padding: 20px;
}

.advantage ul li .text span {
  font-size: 18px;
  font-weight: 500;
  color: #141414;
  line-height: 28px;
}

.advantage ul li .text p {
  margin-top: 10px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
}

.myform {
  width: 800px;
  height: 260px;
  background: #ffffff;
  box-shadow: 0px 1px 30px 0px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 20px;
  margin: 0 auto;
}

.myput {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.myput input {
  width: 45%;
  height: 60px;
  background: #f7f7f7;
  border-radius: 4px;
  border: 0;
  outline: 0;
  margin: 5px;
  font-size: 18px;
  padding: 10px;
  box-sizing: border-box;
}

.btn {
  width: 100%;
  height: 60px;
  background: #ff1918;
  border-radius: 4px;
  font-size: 20px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 60px;
  text-align: center;
  margin-top: 20px;
  cursor: pointer;
}

.cooImg {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.cooImg img {
  width: 18%;
  margin: 5px;
  border: 1px #999 dashed;
  object-fit: contain;
}

.dl {
  margin: 5px 0;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 26px;
}

.ptyscontent {
  display: flex;
}
.baozhang {
  width: 50%;
}
.baozhang img {
  width: 100%;
}
.baozhang2 {
  flex: 1.5;
}
.baozhang2 img {
  width: 100%;
}
.ptyscontent ul {
  flex: 1;
  margin: 20px;
}

.ptyscontent ul li {
  display: flex;
  height: 33.33%;
}

.ptyscontent ul li .item-content {
  flex: 1;
  margin: 0 10px;
}

.ptyscontent ul li div img {
  width: 80px;
  height: 80px;
  margin-right: 16px;
}

.ptyscontent ul li div b {
  display: inline-block;
  margin-bottom: 10px;
  width: 100%;
  text-align: center;
}

.conent {
  width: auto;
  height: 75px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 24px;
}

.ptyscontents {
  display: flex;
  align-items: center;
}

.ptyscontents ul {
  flex: 1;
}

.ptyscontents ul li {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  margin-bottom: 100px;
  border-radius: 5px;
}

.ptyscontents ul li div {
  margin: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ptyscontents ul li div img {
  width: 80px;
  height: 80px;
  margin-bottom: 40px;
}

.ptyscontents ul li div b {
  display: inline-block;
  margin-bottom: 10px;
  width: 100%;
  text-align: center;
}

.btns {
  position: relative;
  top: 10px;
  width: 100px;
  height: 36px;
  border-radius: 18px;
  display: flex;
  color: #fff;
  align-items: center;
  justify-content: center;
  background-color: #1082ff;
}
.toppicd {
  width: 100%;
}
.toppicd img {
  width: 100%;
}
</style>
